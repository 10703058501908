import {
    Box,
    Button,
    Container,
    CssBaseline,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material'
import { FC, ReactElement, useState } from 'react'
import { CurrentUser } from '../CurrentUser'
import NextLink from 'next/link'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'next-i18next'
import { Footer } from '@/PageLayout'
import { setCookie } from '@/utils/cookies'
import { useRouter } from 'next/router'
import { Logo } from './Logo'
import {
    getDefaultPoolEventTheme,
    getPoolEventTheme,
    getPoolEventThemeData,
} from '@/utils/pools_themes'
import Head from 'next/head'
import { useReactNativeWebView } from '@/hooks/useReactNativeWebView'
import { PoolEventResponse } from '@/utils/pool_types'
import { LINEAR_GRADIENT } from '@/utils/poolStyles'
import { Footory } from './Footory'
import { getCookieConsentValue } from 'react-cookie-consent'
import MenuIcon from '@mui/icons-material/Menu'
import { SupportFAB } from '../SupportFAB'

export function getPoolPageLayout(page: ReactElement) {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {page}
            </LocalizationProvider>
        </>
    )
}

type PoolLayoutProps = {
    children: JSX.Element
    poolEventXRefId?: string
    poolEvent?: PoolEventResponse
    showLogo?: boolean
    showSupportFab?: boolean
    showMobileTopMenu?: boolean
    showFooter?: boolean
    secondaryLogo?: string
}

export const PoolLayout: FC<PoolLayoutProps> = (props) => {
    const theme = useTheme()
    const { poolEventXRefId, poolEvent } = props

    let poolTheme = poolEventXRefId && getPoolEventTheme(poolEventXRefId, theme)

    if (!poolTheme) {
        const poolEventThemeData = (poolEvent &&
            getPoolEventThemeData(poolEvent)) ?? {
            headerBackgroundColor: '#fff',
            headerLogoColor: '#000',
            headerTextColor: '#000',
            primaryMainColor: '#049b4a',
            headerPrimaryMainColor: '#049b4a',
            primaryGradient: LINEAR_GRADIENT,
        }

        poolTheme = getDefaultPoolEventTheme(theme, poolEventThemeData)
    }

    const client = global.window
    const cookieConsent = client && getCookieConsentValue()

    return (
        <>
            <Head>
                <meta property="og:site_name" content="Footory Pools" />
                <meta
                    property="og:image"
                    content={`${process.env.NEXT_PUBLIC_BASE_URL}${
                        poolTheme.ogImage ?? '/images/og-image-1200x630.jpg'
                    }`}
                />
                {client && cookieConsent === 'true' && (
                    <script>
                        {window.gtag('consent', 'update', {
                            ad_storage: 'granted',
                            ad_user_data: 'granted',
                            ad_personalization: 'granted',
                            analytics_storage: 'granted',
                        })}
                    </script>
                )}
            </Head>
            <ThemeProvider theme={poolTheme.theme}>
                <CssBaseline />
                <PoolLayoutNoThemeWrapper
                    {...props}
                    secondaryLogo={poolTheme.secondaryLogo}
                />
            </ThemeProvider>
        </>
    )
}

export const PoolLayoutNoThemeWrapper: FC<PoolLayoutProps> = ({
    children,
    secondaryLogo: showSecondaryLogo,
    showFooter = true,
    showLogo = true,
    showSupportFab = true,
    showMobileTopMenu = true,
}) => {
    const theme = useTheme()
    const { t } = useTranslation('pools')
    const router = useRouter()
    const isReactNativeWebView = useReactNativeWebView()
    const [drawerOpen, setDrawerOpen] = useState(false)

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return
            }

            setDrawerOpen(open)
        }

    return (
        <>
            <Box bgcolor={theme.palette.background.poolEventHeader}>
                {!isReactNativeWebView && (
                    <Container>
                        <Box
                            display="flex"
                            gap="10px"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <NextLink href="/pools" passHref>
                                    <Link>
                                        <Box width={32} height={32}>
                                            <Logo
                                                logoColor={
                                                    theme.palette.common
                                                        .poolEventHeaderLogo
                                                }
                                            />
                                        </Box>
                                    </Link>
                                </NextLink>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'block' },
                                }}
                                width={90}
                            >
                                <Footory
                                    logoColor={
                                        theme.palette.common.poolEventHeaderLogo
                                    }
                                />
                            </Box>
                            <CurrentUser />
                            {showMobileTopMenu && (
                                <Box
                                    justifyContent="flex-start"
                                    sx={{
                                        display: {
                                            xs: 'flex',
                                            md: 'none',
                                            marginLeft: 'auto',
                                        },
                                    }}
                                    display="flex"
                                    gap="25px"
                                    alignItems="center"
                                >
                                    <IconButton onClick={toggleDrawer(true)}>
                                        <MenuIcon
                                            color="poolEventHeaderPrimary"
                                            style={{ fontSize: '1.2em' }}
                                        />
                                    </IconButton>
                                </Box>
                            )}
                            <Box
                                display="flex"
                                gap="10px"
                                flexDirection="row"
                                alignItems="center"
                                sx={{ display: { xs: 'none', md: 'flex' } }}
                            >
                                <NextLink passHref href="/pools/me">
                                    <Button color="poolEventHeaderPrimary">
                                        {t('buttonMyPools')}
                                    </Button>
                                </NextLink>
                                <Typography
                                    color={
                                        theme.palette.poolEventHeaderPrimary
                                            ?.main
                                    }
                                >
                                    &#x2022;
                                </Typography>
                                <NextLink passHref href="/pools/learn-more">
                                    <Button color="poolEventHeaderPrimary">
                                        {t('howItWorks')}
                                    </Button>
                                </NextLink>
                                <Typography
                                    color={
                                        theme.palette.poolEventHeaderPrimary
                                            ?.main
                                    }
                                >
                                    &#x2022;
                                </Typography>
                                {/* locales */}
                                <Button
                                    color="poolEventHeaderPrimary"
                                    key={router.locale === 'en' ? 'fr' : 'en'}
                                    sx={{ my: 2, display: 'block' }}
                                    onClick={() => {
                                        const locale =
                                            router.locale === 'en' ? 'fr' : 'en'
                                        setCookie('NEXT_LOCALE', locale, 30)
                                        router.push(router.asPath, undefined, {
                                            locale,
                                        })
                                    }}
                                >
                                    {router.locale === 'en'
                                        ? 'français'
                                        : 'english'}
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                )}
                {showLogo && (
                    <>
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                gap="25px"
                                alignItems="center"
                            >
                                {showSecondaryLogo && (
                                    <img
                                        src={showSecondaryLogo}
                                        height={96}
                                        alt=""
                                    />
                                )}
                                <NextLink passHref href="/pools">
                                    <Link>
                                        <Box width={96} height={96}>
                                            <Logo
                                                logoColor={
                                                    theme.palette.common
                                                        .poolEventHeaderLogo
                                                }
                                            />
                                        </Box>
                                    </Link>
                                </NextLink>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                gap="25px"
                                alignItems="center"
                            >
                                {showSecondaryLogo && (
                                    <img
                                        src={showSecondaryLogo}
                                        height={48}
                                        alt=""
                                    />
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            {children}
            {showFooter && (
                <Box
                    marginTop="100px"
                    paddingBottom="50px"
                    paddingTop="50px"
                    bgcolor="#111"
                    color="#fff"
                >
                    {!isReactNativeWebView && <Footer />}
                    <Container sx={{ marginY: '30px' }}>
                        <Box textAlign="center">{t('madeWithLove')}</Box>
                    </Container>
                    <Container sx={{ marginTop: '30px' }}>
                        <Box textAlign="center">
                            <Link
                                fontSize="12px"
                                color="secondary"
                                href="https://www.flaticon.com/authors/guillem-rodriguez"
                                title="champions icons"
                            >
                                {t('iconsAttribution')}
                            </Link>
                        </Box>
                    </Container>
                </Box>
            )}
            {showMobileTopMenu && (
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 250,
                        }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem disablePadding>
                                <NextLink href="/pools/me" passHref>
                                    <ListItemButton>
                                        <ListItemText
                                            primary={t('buttonMyPools')}
                                        />
                                    </ListItemButton>
                                </NextLink>
                            </ListItem>
                            <ListItem disablePadding>
                                <NextLink href="/pools/learn-more" passHref>
                                    <ListItemButton>
                                        <ListItemText
                                            primary={t('howItWorks')}
                                        />
                                    </ListItemButton>
                                </NextLink>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        const locale =
                                            router.locale === 'en' ? 'fr' : 'en'
                                        setCookie('NEXT_LOCALE', locale, 30)
                                        router.push(router.asPath, undefined, {
                                            locale,
                                        })
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            router.locale === 'en'
                                                ? 'Français'
                                                : 'English'
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
            )}
            {showSupportFab && <SupportFAB />}
        </>
    )
}
