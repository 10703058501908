import { Link, SpeedDial, SpeedDialAction } from '@mui/material'
import { FC } from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InstagramIcon from '@mui/icons-material/Instagram'
import EmailIcon from '@mui/icons-material/Email'

export const SupportFAB: FC = () => {
    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<HelpOutlineIcon fontSize="large" />}
        >
            <SpeedDialAction
                key="ig"
                icon={
                    <Link
                        href="https://ig.me/m/footoryio"
                        target="_blank"
                        rel="noopener"
                    >
                        <InstagramIcon
                            style={{ verticalAlign: 'middle', color: '#000' }}
                        />
                    </Link>
                }
                tooltipTitle="Instagram"
            />
            <SpeedDialAction
                key="messenger"
                icon={
                    <Link
                        href="https://m.me/217299040303856"
                        target="_blank"
                        rel="noopener"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5rem"
                            height="1.5rem"
                            viewBox="0 0 24 24"
                            style={{ verticalAlign: 'middle' }}
                        >
                            <path
                                fill="#000"
                                d="M12 2C6.36 2 2 6.13 2 11.7c0 2.91 1.19 5.44 3.14 7.17c.16.13.26.35.27.57l.05 1.78c.04.57.61.94 1.13.71l1.98-.87c.17-.06.36-.09.53-.06c.9.27 1.9.4 2.9.4c5.64 0 10-4.13 10-9.7C22 6.13 17.64 2 12 2m6 7.46l-2.93 4.67c-.47.73-1.47.92-2.17.37l-2.34-1.73a.6.6 0 0 0-.72 0l-3.16 2.4c-.42.33-.97-.17-.68-.63l2.93-4.67c.47-.73 1.47-.92 2.17-.4l2.34 1.76a.6.6 0 0 0 .72 0l3.16-2.4c.42-.33.97.17.68.63"
                            ></path>
                        </svg>
                    </Link>
                }
                tooltipTitle="Messenger"
            />
            <SpeedDialAction
                key="email"
                icon={
                    <Link
                        href="mailto:info@footory.io"
                        target="_blank"
                        rel="noopener"
                    >
                        <EmailIcon
                            style={{ verticalAlign: 'middle', color: '#000' }}
                        />
                    </Link>
                }
                tooltipTitle="Email"
            />
        </SpeedDial>
    )
}
